/*
* Button Shapes
*
* This file creates the various button shapes
* (ex. Circle, Rounded, Pill)
*/

@each $ubtn-shape in $ubtn-shapes {
  $ubtn-shape-name: nth($ubtn-shape, 1);
  $ubtn-shape-radius: nth($ubtn-shape, 2);

  #{$ubtn-namespace}-#{$ubtn-shape-name} {
    border-radius: $ubtn-shape-radius;
  }
}