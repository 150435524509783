/*
* Button Wrapper
*
* A wrap around effect to highlight
* the shape of the button and offer
* a subtle visual effect.
*/
#{$ubtn-namespace}-wrap {
  border: 1px solid #e3e3e3;
  display: inline-block;
  padding: 9px;

    border-radius: 200px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.04);

}