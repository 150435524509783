/*
* Glowing Buttons
*
* A pulse like glow that appears
* rythmically around the edges of
* a button.
*/

/*
* Glowing Keyframes for various colors
*
*/

@each $ubtn-color in $ubtn-colors {
  $ubtn-name: nth($ubtn-color, 1);
  $ubtn-background: nth($ubtn-color, 2);
  $ubtn-color: nth($ubtn-color, 3);


    @keyframes glowing-#{$ubtn-name} {
    from { box-shadow: 0 0 0 rgba($ubtn-background, 0.3);}
    50% { box-shadow: 0 0 20px rgba($ubtn-background, 0.8);}
    to { box-shadow: 0 0 0 rgba($ubtn-background, 0.3);}
    }

}


/*
* Glowing Buttons Base Styes
*
* A pulse like glow that appears
* rythmically around the edges of
* a button.
*/

#{$ubtn-namespace}-glow {

    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: glowing;

    &:active,
    &.active,
    &.is-active {
      animation-name: none;
    }

}


/*
* Glowing Button Colors
*
* Create colors for glowing buttons
*/

#{$ubtn-namespace}-glow {
  @each $ubtn-color in $ubtn-colors {
    $ubtn-name: nth($ubtn-color, 1);
    $ubtn-background: nth($ubtn-color, 2);
    $ubtn-color: nth($ubtn-color, 3);
    $ubtn-color-namespace: $ubtn-namespace + '-' + $ubtn-name;

    &#{$ubtn-color-namespace} {
      animation-name: glowing-#{$ubtn-name};
    }
  }
}

